<template>
  <div class="pag_wrap">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30],
    },
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
      document.getElementById("index").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
};
</script>

<style lang="less">
.pag_wrap {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-pager li.active {
  color: @themeColor;
}
.el-pager li:hover {
  color: @themeColor;
}
.el-pagination .btn-prev:hover {
  color: @themeColor;
}
.el-pagination .btn-next:hover {
  color: @themeColor;
}
.el-input--suffix:hover .el-input__inner {
  border: 1px solid @themeColor !important;
}
</style>
